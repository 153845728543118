import React from "react";
import { graphql, PageProps } from "gatsby";
import { Layout } from "../../components/layout";
import { Box } from "../../components/rebrand/core/box";
import { Card } from "../../components/rebrand/core/Card/Card";
import { Typography } from "../../components/rebrand/core/Typography/Typography";
import { FormSection } from "../../components/rebrand/sections/Form/FormSection";
import { PageHeaderSection } from "../../components/rebrand/sections/PageHeader/PageHeaderSection";
import { SEO } from "../../components/seo";
import { PartnersPageData } from "../../data/partners";

const { headerData, cardData, formSectionData } = PartnersPageData;

export const pageQuery = graphql`
  query PartnersPage {
    site {
      siteMetadata {
        pages {
          partners {
            title
            description
            keywords
          }
        }
      }
    }
  }
`;

interface PartnersPageProps extends PageProps {
  data: Queries.PartnersPageQuery;
}

export default function PartnersPage({ location: { pathname }, data: { site } }: PartnersPageProps) {

  const { title, description } = site?.siteMetadata?.pages?.partners as Queries.SiteSiteMetadataPagesPartners;

  const seo = {
    title: title as string,
    description: description as string,
    pathname,
  };

  const { gridProps: cardGridProps, items } = cardData;

  return (
    <Layout>
      <SEO {...seo} />
      <PageHeaderSection paddingBottomSpacing={[0, 0]} {...headerData} />
      <FormSection paddingTopSpacing={[7, 10]} {...formSectionData}>
        <Card {...cardGridProps}>
          <Box display="flex" flexDirection="column">
            {items.map((item : object, idx : number) => (
              <Box key={idx} marginBottomSpacing={[6]}>
                <Box width="2rem" height="2rem">
                  {item.Icon}
                </Box>
                <Typography variant="h4" marginTopSpacing={1.75}>
                  {item.title}
                </Typography>
                <Typography variant="body2" marginTopSpacing={1.25}>
                  {item.subtitle}
                </Typography>
              </Box>
            ))}
          </Box>
        </Card>
      </FormSection>
    </Layout>
  );
}

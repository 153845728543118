import React from "react";
import { SvgIcon, SvgIconProps } from "../../../core/svgicon";

export function IconZoomOut(props: SvgIconProps) {
  return (
    <SvgIcon width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
      <path
        d="M20 4L23.0667 7.06667L19.2133 10.8933L21.1067 12.7867L24.9333 8.93333L28 12V4H20ZM4 12L7.06667 8.93333L10.8933 12.7867L12.7867 10.8933L8.93333 7.06667L12 4H4V12ZM12 28L8.93333 24.9333L12.7867 21.1067L10.8933 19.2133L7.06667 23.0667L4 20V28H12ZM28 20L24.9333 23.0667L21.1067 19.2133L19.2133 21.1067L23.0667 24.9333L20 28H28V20Z"
        fill="currentColor"
      />
      <circle cx="15.9999" cy="16" r="5.33333" stroke="currentColor" strokeWidth="2.66667" />
    </SvgIcon>
  );
}

import React from "react";
import { IconHandshake } from "../components/rebrand/icons/base/IconHandshake";
import { IconScroll } from "../components/rebrand/icons/base/IconScroll";
import { IconZoomOut } from "../components/rebrand/icons/base/IconZoomOut";
import { FormSectionProps } from "../components/rebrand/sections/Form/FormSection";
import { PageHeaderSectionProps } from "../components/rebrand/sections/PageHeader/PageHeaderSection";

const headerData: PageHeaderSectionProps = {
  title: "Seamos Partners",
  subtitle:
    "Descubre como nuestras soluciones pueden aportar valor a tus clientes",
};

const cardData = {
  gridProps: {
    gridColumn: ["1 / span 12", "1 / span 6"],
    gridRow: "1",
  },
  items: [
    {
      Icon: <IconZoomOut />,
      title: "Titulo 1",
      subtitle: "Blah Blah",
    },
    {
      Icon: <IconHandshake />,
      title: "Titulo 2",
      subtitle: "Blah Blah",
    },
    {
      Icon: <IconScroll />,
      title: "Titulo 3",
      subtitle: "Blah Blah",
    },
  ],
};

const formSectionData: FormSectionProps = {
  formProps: {
    /* grid props*/
    gridColumn: ["1 / span 12", "7 / span 6"],
    gridRow: ["2", "1"],

    /* content props*/
    title: "Conviértete en nuestro partner",
    type: "partners",
  },
};

export const PartnersPageData = { headerData, cardData, formSectionData };

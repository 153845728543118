import React from "react";
import { SvgIcon, SvgIconProps } from "../../../core/svgicon";

export function IconScroll(props: SvgIconProps) {
  return (
    <SvgIcon width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
      <path
        d="M26 4H24H22H20H18H16H14H12H10H8V21.3333H4V25.3333C4 27.5467 5.78667 29.3333 8 29.3333H24C26.2133 29.3333 28 27.5467 28 25.3333V4H26ZM25.3333 25.3333C25.3333 26.0667 24.7333 26.6667 24 26.6667C23.2667 26.6667 22.6667 26.0667 22.6667 25.3333V21.3333H10.6667V6.66667H25.3333V25.3333Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

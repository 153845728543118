import React from "react";
import { PropsWithChildren } from "react";
import { Form, FormProps } from "../../../forms";
import { Box } from "../../core/box";
import { Card } from "../../core/Card/Card";
import { Grid, GridItemProps } from "../../core/Grid/Grid";
import { Typography } from "../../core/Typography/Typography";
import { Section, SectionProps } from "../Section/Section";

export interface FormSectionProps extends SectionProps {
  formProps: GridItemProps & {
    title: string;
    type: FormProps["type"];
  };
}

export function FormSection(props: PropsWithChildren<FormSectionProps>) {
  const { formProps, children, ...otherProps } = props;
  const { title, type, ...otherFormProps } = formProps;

  return (
    <Section {...otherProps}>
      <Grid gridRowGap={["1.875rem", "1.5rem"]}>
        <Card
          isOutlined={true}
          borderWidth={[0, "0.125rem"]}
          borderRadius={[0, 4]}
          paddingSpacing={[0, 5.75]}
          {...otherFormProps}
        >
          <Typography variant="h3">{title}</Typography>
          <Box marginYSpacing={2} />
          <Form type={type} />
        </Card>
        {children}
      </Grid>
    </Section>
  );
}
